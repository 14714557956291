import React, { createContext, useState, useEffect } from "react";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [colorMode, setColorMode] = useState(() => {
    let savedTheme;
    if (typeof window !== "undefined" && typeof localStorage !== "undefined") {
      savedTheme = localStorage.getItem("colorMode");
    }
    return savedTheme || "dark"; // Default to dark mode
  });

  useEffect(() => {
    localStorage.setItem("colorMode", colorMode);
    document.documentElement.setAttribute("data-theme", colorMode);
  }, [colorMode]);

  return (
    <ThemeContext.Provider value={{ colorMode, setColorMode }}>
      {children}
    </ThemeContext.Provider>
  );
};
